import { graphql, Link } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import styled, { keyframes } from 'styled-components';
import { Layout } from '../components/Layout';
import { Images } from '../constants/Images';
import { QueryProps } from '../interfaces';

const SplashContainer = styled.div`
  display: grid;
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  @media (min-width: 768px) {
    min-height: 100vh;
  }
`;

const Bg = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const ContentGrid = styled.div`
  display: grid;
  position: relative;
  padding: 50px 0;
  grid-template-rows: auto 1fr auto auto;
  justify-self: center;
  justify-items: center;
  z-index: 1;
  width: 100%;
  @media (min-width: 768px) {
    padding: 100px 0;
  }
  @media (min-width: 1280px) {
    width: 1280px;
  }
`;

const CardGrid = styled.div`
  position: relative;
  margin-top: 150px;
  display: flex;
  align-items: center;
  overflow: hidden;
  @media (min-width: 768px) {
    width: 100vw;
    height: 56vw;
  }
  @media (min-width: 1280px) {
    width: 60vw;
    height: 34vw;
  }
`;

const CardContent = styled.div`
  position: relative;
  padding: 3vw 5.5vw;
  z-index: 1;
`;

const SolarBg = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const MuskLogo = styled.img`
  display: block;
  width: 200px;
  height: auto;
  overflow: hidden;
`;

const Title = styled.h1`
  padding: 0 20px;
  color: #fff;
  font-size: 20px;
  text-shadow: 0 0 20px #000;
  @media (min-width: 768px) {
    padding: 0;
    font-size: 2.2vw;
  }
  @media (min-width: 1280px) {
    font-size: 2vw;
  }
`;

const TitleLogo = styled.img`
  display: block;
  width: 600px;
  height: auto;
  overflow: hidden;
  width: 100%;
  @media (min-width: 768px) {
    width: 600px;
  }
`;

const SubTitle = styled.h2`
  font-family: 'Lato';
  padding: 0 20px;
  color: #eee;
  font-size: 15px;
  font-weight: 400;
  margin: 10px 0;
  @media (min-width: 768px) {
    padding: 0;
    font-size: 1.6vw;
  }
  @media (min-width: 1280px) {
    font-size: 1.5vw;
  }
`;

const FormContent = styled.div`
  display: grid;
  background: #aa2318;
`;

const Rewards = styled.div`
  display: grid;
  gap: 10px;
  width: 50%;
  border-top: 1px solid rgba(251, 193, 63, 0.5);
  @media (min-width: 768px) {
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 80px;
  }
`;

const Reward = styled.div`
  @media (min-width: 1280px) {
    padding: 0 30px;
  }
`;

const RewardTitle = styled.h3`
  font-family: 'Lato';
  color: #fbc13f;
  font-size: 28px;
  font-weight: 400;
  margin: 10px 0;
  @media (min-width: 768px) {
    margin: 20px 0;
    font-size: 35px;
  }
  @media (min-width: 1280px) {
    margin: 30px 0;
    font-size: 50px;
  }
`;

const RewardImage = styled.img`
  display: block;
  width: 100%;
  height: auto;
  overflow: hidden;
`;

const PeopleImage = styled.img`
  display: block;
  width: 100%;
  height: auto;
  overflow: hidden;
`;

const Disclaimer = styled.div`
  display: grid;
  justify-self: center;
  text-align: center;
  color: #fff;
  font-size: 14px;
  padding: 30px;
  @media (min-width: 1280px) {
    width: 1280px;
  }
`;

const stickerAnim1 = keyframes`
  from {
    transform: scale(0.5) translate(450px, -200px);
  }
  to {
    transform: scale(1) translate(0, 0);
  }
`;

const stickerAnim2 = keyframes`
  from {
    transform: scale(0.5) translate(200px, -150px);
  }
  to {
    transform: scale(1) translate(0, 0);
  }
`;

const stickerAnim3 = keyframes`
  from {
    transform: scale(0.5) translate(250px, -200px);
  }
  to {
    transform: scale(1) translate(0, 0);
  }
`;

const StickerLayout = styled.div`
  position: absolute;
  height: 50%;
  width: 100%;
  transition: 2s ease-out;
  transform: scale(0.8);
  @media (min-width: 768px) {
    transform: scale(0.9);
  }
  @media (min-width: 1280px) {
    transform: scale(1);
  }
`;

const Sticker1 = styled.img`
  position: absolute;
  width: 150px;
  left: calc(100% / 3 - 150px);
  top: 26%;
  animation-iteration-count: 1;
  animation: ${stickerAnim1} 1s ease-out;
`;

const Sticker2 = styled.img`
  position: absolute;
  width: 120px;
  right: calc(100% / 2 - 150px);
  top: 33%;
  animation-iteration-count: 1;
  animation: ${stickerAnim2} 0.8s ease-out;
`;

const Sticker3 = styled.img`
  position: absolute;
  width: 120px;
  right: calc(100% / 3 - 150px);
  top: 12%;
  animation-iteration-count: 1;
  animation: ${stickerAnim3} 0.5s ease-out;
`;

const FormGrid = styled.div`
  display: grid;
  justify-self: center;
  padding: 0;
  gap: 20px;
  @media (min-width: 768px) {
    padding: 20px 20px;
    grid-template-columns: 1fr auto;
  }
  @media (min-width: 1280px) {
    padding-top: 30px 0;
    width: 1080px;
  }
`;

const TypeBlock = styled.div`
  padding: 0 20px;
  @media (min-width: 768px) {
    padding: 0;
  }
  a {
    color: #fff;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const TypeHtml = styled.div`
  font-family: 'Lato';
  color: #f4f4f4;
  font-size: 16px;
  line-height: 32px;
  span {
    color: #666;
  }
`;

const TypeHtmlInline = styled.div`
  font-family: 'Lato';
  color: #f4f4f4;
  font-size: 16px;
  line-height: 32px;
  span {
    color: #666;
  }
  @media (min-width: 768px) {
    font-size: 1.5vw;
  }
  @media (min-width: 1280px) {
    font-size: 1vw;
  }
`;

const LinkSet = styled.div`
  display: inline-grid;
  align-content: flex-start;
  gap: 20px;
  margin-top: 50px;
`;

const SiteLink = styled.a`
  color: #fff;
  font-family: 'Lato';
  text-decoration: none;
  align-items: center;
  padding: 30px 0;
  font-weight: bold;
  @media (hover: hover) {
    &:hover {
      color: #c3d3e0;
    }
  }
`;

const SocialLink = styled.a`
  display: inline-grid;
  grid-template-columns: 50px 1fr;
  color: #fff;
  font-family: 'Lato';
  text-decoration: none;
  align-items: center;
  @media (hover: hover) {
    &:hover {
      color: #c3d3e0;
    }
  }
`;

const InternalLink = styled(Link)`
  color: #fff;
  font-family: 'Lato';
  text-decoration: none;
  @media (hover: hover) {
    &:hover {
      color: #eee;
      text-decoration: underline;
    }
  }
`;

const LinkImage = styled.img`
  width: 30px;
`;

const SideNote = styled.div`
  font-family: 'Lato';
  color: #f4f4f4;
  font-size: 16px;
  line-height: 32px;
  span {
    color: #666;
  }
`;

export default ({ data }: QueryProps) => {
  const node = data.allMarkdownRemark.nodes[0];
  const html = node.html ?? '';
  return (
    <Layout>
      <Helmet>
        <meta
          name="description"
          content="MUSK Gold Rush - Gold of the Galaxy"
        />
      </Helmet>
      <SplashContainer>
        <Bg src={Images.GoldRushBg} alt="" />
        <StickerLayout>
          <Sticker1 src={Images.Sticker1} alt="" />
          <Sticker2 src={Images.Sticker2} alt="" />
          <Sticker3 src={Images.Sticker3} alt="" />
        </StickerLayout>
        <ContentGrid>
          <MuskLogo src={Images.MuskLogoRush} alt="" />
          <CardGrid>
            <SolarBg src={Images.SolarBg} alt="" />
            <CardContent>
              <Title>Musk Gold Rush</Title>
              <SubTitle>Refer-to-Earn Solar Rewards Contest</SubTitle>
              <TypeBlock>
                <TypeHtmlInline>
                  <p>69,000 $MUSK up for grabs</p>
                  <p>5 Referrals will each win 10,000 $MUSK!</p>
                  <p>1 will win 19,000</p>
                  <p>
                    $MUSK Rules: Submit a Solar Program referral at{' '}
                    <a href="https://solar.musk.gold">Solar.Musk.Gold</a>. In
                    order of submission, the Team will verify the Solar referral
                    for qualification. First 5 qualified referrals win the
                    reward!
                  </p>
                  <p>
                    Bonus Reward: Of the first 25 qualified referrals, one lucky
                    referral (chosen at random) will win 19,000 $MUSK.
                  </p>
                  <p>
                    Every Solar filled out form is an automatic submission into
                    the contest.
                  </p>
                </TypeHtmlInline>
              </TypeBlock>
            </CardContent>
          </CardGrid>
          <SiteLink href="https://musk.gold" title="Musk Gold">
            Visit us at musk.gold
          </SiteLink>
        </ContentGrid>
      </SplashContainer>
      <FormContent>
        <FormGrid>
          <TypeBlock>
            <TypeHtml dangerouslySetInnerHTML={{ __html: html }} />
            <LinkSet>
              <SocialLink
                href="https://twitter.com/Muskgold"
                title="Musk Gold Twitter"
              >
                <LinkImage src={Images.SocialTwitter} alt="Musk Gold Twitter" />
                Twitter
              </SocialLink>
              <SocialLink
                href="https://t.me/muskgold"
                title="Musk Gold Telegram"
              >
                <LinkImage
                  src={Images.SocialTelegram}
                  alt="Musk Gold Telegram"
                />
                Telegram
              </SocialLink>
              <InternalLink to="/terms/">
                Read Contest Terms & Conditions
              </InternalLink>
            </LinkSet>
          </TypeBlock>
        </FormGrid>
        <PeopleImage src={Images.People} alt="" />
        <Disclaimer>
          Disclaimer: Elon Musk nor his companies are involved with $MUSK Gold.
        </Disclaimer>
      </FormContent>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(filter: { frontmatter: { title: { eq: "Index" } } }) {
      nodes {
        html
      }
    }
  }
`;
